/* stylelint-disable indentation */
import { styled, css } from "styled-components";

import Button from "components/Button";
import FilterBar from "components/FilterBar";
import {
    Introduction as HeroIntroduction,
    IntroductionHtml as HeroIntroductionHtml,
} from "components/Hero/Hero.styled";
import NewsCarousel from "components/NewsCarousel";
import { SlideWrapper } from "components/NewsCarousel/NewsCarousel.styled";
import { Heading as NewsLetterSignupHeading } from "components/NewsletterSignup/NewsletterSignup.styled";
import PageContent from "components/PageContent";
import { Wrapper as SectionWrapper } from "components/Section/Section.styled";
import { Container as GridContainer } from "style/components/Page";
import { columnGap } from "style/grid";
import { headerSizes, headerSizesWithOffset } from "style/headerOffsets";
import { MQ } from "style/mediaQueries";

export const Carousel = styled(NewsCarousel)`
    ${SlideWrapper} {
        padding-top: 4.375rem;

        ${MQ.FROM_M} {
            padding-top: 5.4375rem;
        }

        ${MQ.FROM_XL} {
            padding-top: 7.3125rem;
        }
    }
`;

export const HeroContainer = styled(GridContainer).attrs<{
    $hasCarousel?: boolean;
}>(() => ({}))`
    align-content: flex-start;
    ${columnGap};
    display: grid;
    grid-template-areas:
        "heading"
        "navigation"
        "introduction"
        "col"
        "readMore";
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 4.8125rem;
    padding-top: ${(props) => (props.$hasCarousel ? "4.375rem" : "8.75rem")};
    position: relative;

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
        padding-top: ${(props) =>
            props.$hasCarousel ? "4.375rem" : "9.75rem"};
    }

    ${MQ.FROM_L} {
        grid-template-areas:
            "heading col"
            "navigation col"
            "introduction col"
            "readMore readMore";
    }

    ${MQ.FROM_XL} {
        padding-top: ${(props) =>
            props.$hasCarousel ? "4.375rem" : "11.6875rem"};
    }
`;

export const NewsItems = styled(GridContainer)`
    ${columnGap};
    display: grid;
    grid-template-columns: repeat(4, [col-start] 1fr);
    row-gap: 2.625rem;

    ${MQ.FROM_M} {
        row-gap: 3.5rem;
        grid-template-columns: repeat(12, [col-start] 1fr);
    }

    ${MQ.FROM_XL} {
        row-gap: 4.375rem;
    }
`;

export const CardWrapper = styled.div.attrs<{
    $size: "small" | "medium";
}>(() => ({}))`
    grid-column: auto / span 4;

    ${MQ.FROM_M} {
        grid-column: auto / span 6;
    }

    ${MQ.FROM_L} {
        grid-column: ${(props) =>
            props.$size == "small" ? "auto / span 4" : "auto / span 6"};
    }
`;

export const TypeSubFilterBar = styled(FilterBar)`
    && {
        grid-area: navigation;
        grid-column: col-start 1 / span 4;

        ${MQ.FROM_M} {
            grid-column: col-start 1 / span 12;
        }

        ${MQ.FROM_L} {
            grid-column: col-start 1 / span 8;
        }

        ${MQ.FROM_XL} {
            grid-column: col-start 1 / span 9;
        }
    }
`;

export const TypeFilterBar = styled(FilterBar).attrs<{
    $headerVisible: boolean;
}>(() => ({}))`
    && {
        transition: top 0.2s ease-in-out;
        ${(props) =>
            props.$headerVisible
                ? css`
                      top: calc(${headerSizes.S} + 1px);

                      ${MQ.FROM_M} {
                          top: calc(${headerSizes.M} + 1px);
                      }

                      ${MQ.FROM_XL} {
                          top: calc(${headerSizes.XL} + 1px);
                      }
                  `
                : css`
                      top: 0;
                  `};
    }
`;

export const SubjectFilterBar = styled(FilterBar).attrs<{
    $headerVisible?: boolean;
    $typesNavVisible?: boolean;
}>(() => ({}))`
    && {
        top: calc(
            ${(props) => (props.$headerVisible ? headerSizes.S : "0px")} +
                ${(props) => (props.$typesNavVisible ? "3.5rem" : "0px")}
        );

        ${MQ.FROM_M} {
            top: calc(
                ${(props) => (props.$headerVisible ? headerSizes.M : "0px")} +
                    ${(props) => (props.$typesNavVisible ? "3.5rem" : "0px")}
            );
        }

        ${MQ.FROM_XL} {
            top: calc(
                ${(props) => (props.$headerVisible ? headerSizes.XL : "0px")} +
                    ${(props) => (props.$typesNavVisible ? "4.375rem" : "0px")}
            );
        }
    }
`;

export const introductionStyle = css`
    margin-top: 1.75rem;

    ${MQ.FROM_M} {
        margin-top: 2.625rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 3.5rem;
    }
`;

export const Introduction = styled(HeroIntroduction)`
    ${introductionStyle}
`;

export const IntroductionHtml = styled(HeroIntroductionHtml)`
    ${introductionStyle}
`;

export const LoadMore = styled(Button)`
    grid-column: auto / span 4;
    margin-top: 1.75rem;
    place-self: center center;

    ${MQ.FROM_M} {
        grid-column: auto / span 12;
        margin-top: 2.625rem;
    }

    ${MQ.FROM_XL} {
        margin-top: 4.375rem;
    }
`;

export const ExtraPageContent = styled(PageContent).attrs<{
    $headerVisible?: boolean;
}>(() => ({}))`
    && {
        ${SectionWrapper} {
            scroll-margin-top: 7rem;

            ${MQ.FROM_XL} {
                scroll-margin-top: 8.75rem;
            }
        }

        ${NewsLetterSignupHeading} {
            ${MQ.FROM_L} {
                top: calc(7rem + 1.3125rem);
            }

            ${MQ.FROM_XL} {
                top: calc(8.75rem + 1.3125rem);
            }

            ${(props) =>
                props.$headerVisible &&
                css`
                    ${MQ.FROM_M} {
                        top: ${`calc(${headerSizesWithOffset.M} + 7rem)`};
                    }

                    ${MQ.FROM_XL} {
                        top: ${`calc(${headerSizesWithOffset.XL} + 8.75rem)`};
                    }
                `};
        }
    }
`;
