import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import NewsCarouselProps from "./NewsCarouselProps";

const NewsCarousel = loadable(
    (): Promise<DefaultComponent<NewsCarouselProps>> =>
        import(/* webpackChunkName: "NewsCarousel" */ "./NewsCarousel"),
);
const NewsCarouselLoader = (props: NewsCarouselProps): ReactElement => (
    <NewsCarousel {...props} />
);
export default NewsCarouselLoader;
