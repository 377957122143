/* eslint-disable import/no-unresolved */
import { styled, css } from "styled-components";
import theme from "styled-theming";
import { Swiper } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

import Button from "components/Button";
import Icon from "components/Icon";
import { Container as GridContainer } from "style/components/Page";
import {
    BodyL,
    LabelM,
    LabelS,
    styleHeadingL,
} from "style/components/Typography";
import { columnGap, GUTTER } from "style/grid";
import { MQ } from "style/mediaQueries";
import { BASE_MAX_SIZE } from "style/settings";

export const Pagination = css<{
    $sliderPaused?: boolean;
}>`
    ${columnGap}
    bottom: 0;
    display: flex;
    margin: auto;
    max-width: ${BASE_MAX_SIZE}px;
    padding: 0 ${GUTTER.SM};
    position: relative;
    width: 100%;

    ${MQ.FROM_M} {
        padding: 0 ${GUTTER.MD};
    }

    ${MQ.FROM_XL} {
        padding: 0 ${GUTTER.XL};
    }

    .swiper-pagination-bullet {
        background: var(--line-on-image-default);
        border-radius: 0;
        height: 1px;
        margin-top: -1.75rem;
        opacity: 1;
        position: relative;
        width: 100%;

        &::before {
            content: "";
            display: block;
            height: 1.75rem;
            inset: 0;
            margin-top: -0.875rem;
        }

        &-active {
            overflow: hidden;

            &::before {
                animation: slide-progress 5s linear;
                background: var(--icon-on-image-primary);
                position: relative;
                ${(props) =>
                    props.$sliderPaused &&
                    css`
                        animation: none;
                        transform: translateX(-100%);
                    `}

                @keyframes slide-progress {
                    0% {
                        transform: translateX(-100%);
                    }

                    100% {
                        transform: translateX(0);
                    }
                }
            }
        }
    }
`;

export const Wrapper = styled.div.attrs<{
    $sliderPaused: boolean;
}>(() => ({}))`
    display: flex;
    max-width: 100%;
    width: 100%;

    .swiper-horizontal > .swiper-pagination-bullets,
    .swiper-pagination-bullets.swiper-pagination-horizontal,
    .swiper-pagination-custom,
    .swiper-pagination-fraction {
        ${Pagination}
    }
`;

export const Slides = styled(Swiper)`
    .swiper-wrapper {
        height: auto;
    }

    .swiper-slide {
        align-self: stretch;
        box-sizing: border-box;
        display: flex;
        height: auto;
    }
`;

export const SlideWrapper = styled.div`
    align-items: center;
    background: var(--block-blue-default);
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    position: relative;
    width: 100%;
`;

export const SlideContent = styled(GridContainer)`
    ${columnGap}
    display: grid;
    grid-template-areas:
        "categories watermark"
        "heading watermark"
        "published watermark"
        "introduction introduction"
        "readmore readmore";
    grid-template-columns: repeat(4, [col-start] 1fr);
    padding-bottom: 4.375rem;
    width: 100%;

    figure {
        z-index: 1;
    }

    ${MQ.FROM_M} {
        grid-template-columns: repeat(12, [col-start] 1fr);
    }
`;

export const Categories = styled(LabelM)`
    color: var(--text-on-image-primary);
    grid-area: categories;
    grid-column: col-start 1 / span 3;
    margin-bottom: 0.875rem;
    margin-top: 0;
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 6;
    }
`;

export const Watermark = styled(Icon)`
    color: var(--icon-on-image-primary);
    grid-area: watermark;
    grid-column: 4 / span 1;
    justify-self: flex-end;
    margin-bottom: 0.875rem;
    margin-top: -0.875rem;
    z-index: 2;
    ${theme("hasImage", {
        true: css`
            filter: brightness(0) saturate(100%) invert(100%)
                var(--shadow-on-image);
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: col-start 11 / span 2;
        margin-top: 0;
    }
`;

export const Heading = styled.h2`
    ${styleHeadingL}
    color: var(--text-on-image-primary);
    grid-area: heading;
    grid-column: col-start 1 / span 4;
    margin-bottom: 0.875rem;
    margin-top: 0;
    z-index: 2;

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 6;
    }
`;

export const Published = styled(LabelS)`
    color: var(--text-on-image-primary);
    grid-area: published;
    grid-column: col-start 1 / span 3;
    margin-bottom: 1.75rem;
    margin-top: 0;
    z-index: 2;
    ${theme("hasImage", {
        true: css`
            filter: brightness(0) saturate(100%) invert(100%)
                var(--shadow-on-image);
        `,
    })};

    ${MQ.FROM_M} {
        grid-column: col-start 1 / span 8;
    }

    ${MQ.FROM_L} {
        grid-column: col-start 1 / span 6;
    }
`;

export const Text = styled(BodyL)`
    color: var(--text-on-image-primary);
    grid-area: introduction;
    grid-column: col-start 1 / span 4;
    margin-bottom: 2.1875rem;
    z-index: 2;
    ${theme("hasImage", {
        true: css`
            filter: brightness(0) saturate(100%) invert(100%)
                var(--shadow-on-image);
        `,
    })};

    ${MQ.FROM_M} {
        margin-bottom: 2.625rem;
        grid-column: col-start 5 / span 8;
    }

    ${MQ.FROM_XL} {
        grid-column: col-start 7 / span 6;
    }
`;

export const ReadMore = styled(Button)`
    && {
        grid-area: readmore;
        grid-column: col-start 1 / span 4;
        justify-self: flex-start;
        z-index: 2;

        ${MQ.FROM_M} {
            grid-column: col-start 5 / span 8;
        }

        ${MQ.FROM_XL} {
            grid-column: col-start 7 / span 6;
        }
    }
`;
