import loadable, { DefaultComponent } from "@loadable/component";
import React, { ReactElement } from "react";

import FilterBarProps from "./FilterBarProps";

const FilterBar = loadable(
    (): Promise<DefaultComponent<FilterBarProps>> =>
        import(/* webpackChunkName: "FilterBar" */ "./FilterBar"),
);
const FilterBarLoader = (props: FilterBarProps): ReactElement => (
    <FilterBar {...props} />
);
export default FilterBarLoader;
