import React, { ReactElement } from "react";
import { useDispatch } from "react-redux";
import { ThemeProvider } from "styled-components";

import CallToAction from "components/CallToAction";
import { Heading, Col, CallToActions } from "components/Hero/Hero.styled";
import PageCard from "components/PageCard";
import Section from "components/Section";
import useHeader from "hooks/useHeader";
import useIsInEditMode from "hooks/useIsInEditMode";
import { APPEND_DATA_BEGIN } from "store/actionTypes";
import { Wrapper } from "style/components/Page";
import { BodyL } from "style/components/Typography";
import { addEditAttributes } from "utils/episerver";
import getComponentTypeForContent from "utils/getComponentTypeForContent";

import {
    Carousel,
    HeroContainer,
    TypeFilterBar,
    TypeSubFilterBar,
    SubjectFilterBar,
    NewsItems,
    CardWrapper,
    LoadMore,
    Introduction,
    ExtraPageContent,
    IntroductionHtml,
} from "./NewsListingPage.styled";
import NewsListingPageProps, { NewsListingProps } from "./NewsListingPageProps";

const NewsListingPage = ({
    hero,
    topNews,
    blocks,
    identifier,
    theme = "lightgray",
    contentTheme = "black",
    paginatedList,
    l18n,
    mainContent = null,
}: NewsListingPageProps): ReactElement => {
    const pageSize = paginatedList.pageSize || 21;
    const headerState = useHeader();
    const dispatch = useDispatch();

    const handleLoadMore = () => {
        dispatch({
            type: APPEND_DATA_BEGIN,
            payload: {
                path: location.pathname,
                search: paginatedList.currentCategoryFilterQuery,
                page: paginatedList.currentPage + 1,
                pageSize: pageSize,
            },
        });
    };

    const showLoadMore = (list: NewsListingProps | undefined): boolean => {
        if (list?.items === undefined || list.totalItems <= list.items.length) {
            return false;
        }
        const lastPage = Math.ceil(list.totalItems / pageSize);
        return list.currentPage < lastPage;
    };

    const isInEditMode = useIsInEditMode();

    const hasTopNews = topNews !== undefined && topNews.length > 0;

    return (
        <ThemeProvider theme={{ theme: theme }}>
            <Wrapper id="maincontent">
                {(isInEditMode || hasTopNews) && (
                    <Carousel
                        items={topNews}
                        l18n={l18n}
                        editPropertyName="TopNewsPages"
                    />
                )}
                {paginatedList?.typeCategories &&
                    paginatedList?.typeCategories?.length > 0 && (
                        <TypeFilterBar
                            gtmClickType="newslisting-filter"
                            items={paginatedList?.typeCategories}
                            $headerVisible={headerState.visible}
                        />
                    )}
                <Section
                    theme={theme}
                    key={`heroContent`}
                    alignItems="center"
                    id={identifier}
                >
                    <HeroContainer $hasCarousel={hasTopNews}>
                        {(isInEditMode || hero.heading) && (
                            <Heading
                                {...addEditAttributes("Heading")}
                                dangerouslySetInnerHTML={{
                                    __html: hero.heading || "",
                                }}
                                size={hero.headingSize || "XXL"}
                            />
                        )}
                        {paginatedList?.typeSubCategories &&
                            paginatedList?.typeSubCategories.length > 0 && (
                                <TypeSubFilterBar
                                    gtmClickType="newslisting-filter"
                                    items={paginatedList?.typeSubCategories}
                                    variant="pills"
                                />
                            )}
                        {(isInEditMode ||
                            (!hero.introductionHtml && hero.introduction)) && (
                            <Introduction
                                {...addEditAttributes(`Introduction`)}
                                dangerouslySetInnerHTML={{
                                    __html: hero.introduction || "",
                                }}
                            />
                        )}
                        {(isInEditMode || hero.introductionHtml) && (
                            <IntroductionHtml
                                editPropertyName={"IntroductionHtml"}
                                content={hero.introductionHtml}
                                renderElement={<BodyL />}
                            />
                        )}
                        {(isInEditMode || hero.ctas) && (
                            <Col>
                                <CallToActions
                                    {...addEditAttributes("CallToActions")}
                                >
                                    {hero.ctas?.map((cta, index) => {
                                        return (
                                            <CallToAction
                                                key={`CallToAction${index}`}
                                                contentTheme={contentTheme}
                                                {...cta}
                                            />
                                        );
                                    })}
                                </CallToActions>
                            </Col>
                        )}
                    </HeroContainer>
                </Section>

                {mainContent && (
                    <ExtraPageContent
                        key={`MainContent`}
                        areaName="MainContent"
                        mainContent={mainContent}
                        componentSelector={getComponentTypeForContent}
                        $headerVisible={headerState.visible}
                        {...addEditAttributes("MainContent")}
                    />
                )}

                {paginatedList?.subjectCategories &&
                    paginatedList?.subjectCategories.length > 0 && (
                        <SubjectFilterBar
                            gtmClickType="newslisting-filter"
                            items={paginatedList?.subjectCategories}
                            $headerVisible={headerState.visible}
                            $typesNavVisible={
                                paginatedList?.typeCategories &&
                                paginatedList?.typeCategories?.length > 0
                            }
                        />
                    )}
                {paginatedList?.items !== undefined &&
                    paginatedList.items.length > 0 && (
                        <NewsItems>
                            {paginatedList.items?.map((cardData, index) => {
                                const size = index > 1 ? "small" : "medium";
                                return (
                                    <CardWrapper
                                        key={`NewsCard${index}`}
                                        $size={size}
                                    >
                                        <PageCard
                                            {...cardData}
                                            size={size}
                                            prefetch={1000} // delay on prefetching in ms
                                        />
                                    </CardWrapper>
                                );
                            })}
                            {showLoadMore(paginatedList) && (
                                <LoadMore
                                    variant="outline"
                                    actionTheme="black"
                                    size="large"
                                    onClick={handleLoadMore}
                                >
                                    {l18n?.loadMore || "Load more"}
                                </LoadMore>
                            )}
                        </NewsItems>
                    )}

                <ExtraPageContent
                    key={`Blocks`}
                    areaName="Blocks"
                    mainContent={blocks}
                    componentSelector={getComponentTypeForContent}
                    $headerVisible={headerState.visible}
                    {...addEditAttributes("Blocks")}
                />
            </Wrapper>
        </ThemeProvider>
    );
};

export default React.memo(NewsListingPage);
